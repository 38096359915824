import React from "react";
import { Section } from "../Section";
import { RichText } from "../RichText";
import { getColor } from "../../utils/classPicker";
import BlueWave from "../../images/blue-wave.png";

export const SectionOne = ({ title, description }) => {
  return (
    <>
      <div
        className="position-relative bg-md-blue-gradient"
        style={{
          backgroundImage: `url(${BlueWave})`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          padding: "5% 0 10%",
        }}
      >
        <Section width={11} className="py-5rem">
          <div className={`col-12 col-lg-24 d-flex flex-column ${getColor(0)}`}>
            <h2 className="fs-5 extra-bold pb-4 c-white w-md-75">{title}</h2>
            <RichText
              className="pb-2rem w-auto w-md-75 c-white"
              text={description.raw}
            />
          </div>
        </Section>
      </div>
    </>
  );
};
