import "../scss/app.scss";
import React from "react";

import { SectionOne } from "../component/merchant/SectionOne";
import { SectionTextImage } from "../component/SectionTextImage";
import { SectionTitleText } from "../component/SectionTitleText";
import { FooterMerchant } from "../component/FooterMerchant";
import AppHelmet from "../component/AppHelmet";
import { usePageInit } from "../component/hook/usePageInit";
import { Cookies } from "../component/Cookies";

const components = {
  ContentfulSectionOne: SectionOne,
  ContentfulSectionTextImage: SectionTextImage,
  ContentfulSectionTitleText: SectionTitleText,
  ContentfulFooter: FooterMerchant,
};

function MerchantPage({ pageContext }) {
  const { sections, name } = pageContext;
  const { popup } = usePageInit();

  return (
    <>
      <AppHelmet title={name} />
      {popup}
      {sections?.map(({ id, __typename, ...props }) => {
        const SectionTag = components[__typename];
        return SectionTag && <SectionTag {...props} key={id} />;
      })}
      <Cookies />
    </>
  );
}

export default MerchantPage;
