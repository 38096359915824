import React from "react";
import { RichText } from "./RichText";
import { Section } from "./Section";
import { Buttons } from "./Common";
import textUtils from "../utils/text";

export const SectionTitleText = ({
  title,
  titleFontWeight = "extra-bold",
  titleColor,
  titleFontSize,
  titlePaddingBottom = 0,
  buttons,
  titleAlign = "",
  separator,
  separatorSize = 1,
  description,
  backgroundColor,
  spaceBottom,
  spaceTop,
  width = 12,
}) => {
  return (
    <Section
      width={11}
      className={`py-3rem  pb-lg-${spaceBottom || 5}rem pt-lg-${
        spaceTop || 5
      }rem ${backgroundColor}`}
    >
      <div className={`col-${width} text-center`}>
        {title && (
          <h2
            className={`fs-${titleFontSize || "1_75"} ${titleFontWeight} ${
              titleColor || "c-black"
            } ${titleAlign} pb-${titlePaddingBottom}rem`}
          >
            {textUtils.format(title)}
          </h2>
        )}
        {separator && (
          <div className={`position-relative py-${separatorSize}rem`}>
            <div
              className="position-absolute border-top bw-2 bc-blue"
              style={{ width: "154px", left: "calc(50% - 77px)" }}
            />
          </div>
        )}
        {description && <RichText text={description.raw} />}
        {buttons && <Buttons buttons={buttons} className="d-none d-lg-block" />}
      </div>
    </Section>
  );
};
