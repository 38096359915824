import { Section } from "./Section";
import React from "react";
import { getColor } from "../utils/classPicker";
import { RichText } from "./RichText";

export const FooterMerchant = ({ copyrights }) => (
  <Section bgColor={10}>
    <div className={`col-12 d-flex flex-column ${getColor(1)}`}>
      <RichText text={copyrights?.raw} className={"rich-text__raw"} />
    </div>
  </Section>
);
