import React from "react";
import { Section } from "./Section";
import { TextColumn, Buttons } from "./Common";
import clsx from "clsx";

export const SectionTextImage = ({
  imagePosition,
  title,
  description,
  image,
  backgroundColor,
  buttons,
  smallText,
  imageAlign,
  spaceBottom = 5,
  spaceTop = 5,
  separator,
  aosOffset,
  leftColWidth,
  separatorSize = 1,
  imageWidth = 100,
  textColor = "c-black",
  iconClass = "",
  isPromotionalVideo,
}) => {
  const imageColumn = image && (
    <ImageColumn
      imageSrc={image.fluid.src}
      imagePosition={imagePosition}
      imageAlign={imageAlign}
      imageWidth={imageWidth}
      aosOffset={aosOffset}
      leftColWidth={leftColWidth}
    />
  );

  return (
    <Section
      width={11}
      className={`py-3rem pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem ${backgroundColor} ${textColor}`}
    >
      {imagePosition === "Left" && imageColumn}
      <TextColumn
        title={title}
        description={description}
        buttons={buttons}
        smallText={smallText}
        separator={separator}
        separatorSize={separatorSize}
        position={imagePosition === "Left" ? "right" : "left"}
        className={clsx("pb-2rem pb-lg-0", { "mx-auto": isPromotionalVideo })}
        aosOffset={aosOffset}
        leftColWidth={leftColWidth}
        iconClass={iconClass}
        isPromotionalVideo={isPromotionalVideo}
      />
      {(!imagePosition || imagePosition === "Right") && imageColumn}
      {isPromotionalVideo && (
        <>
          <div className="w-100 text-center order-2">
            <div className={`position-relative pt-1rem pb-2rem`}>
              <div
                className="position-absolute border-top bw-2 bc-blue"
                style={{ width: "154px", left: "calc(50% - 77px)" }}
              />
            </div>
            <iframe
              width="80%"
              style={{ aspectRatio: "16 / 9" }}
              src={`https://www.youtube.com/embed/09jHFqb-oeY`}
              frameBorder="0"
              title="Instapay"
            />
          </div>
        </>
      )}
      {buttons && (
        <div className="col-12 order-11 d-block d-lg-none pt-4rem text-center">
          <Buttons buttons={buttons} />
        </div>
      )}
    </Section>
  );
};

const ImageColumn = ({
  imageSrc,
  imagePosition,
  imageAlign,
  imageWidth,
  aosOffset,
  leftColWidth = 6,
}) => {
  let imageClass = "pr-2rem";
  let colWidth = leftColWidth;

  if (imagePosition === "Right") {
    imageClass = "pl-2rem";
    colWidth = 12 - parseInt(leftColWidth);
  }

  return (
    <div
      className={`col-12 order-11 col-lg-${colWidth || 6} text-center text-lg-${
        imageAlign || "center"
      }`}
    >
      <img
        className={`d-inline-block max-w-${
          imageWidth || 100
        } px-0 ${imageClass}`}
        src={imageSrc}
        alt="section three"
        data-aos="fade-up"
        data-aos-offset={aosOffset || 0}
      />
    </div>
  );
};
